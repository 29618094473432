'use client';

import { useRef } from 'react';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
const Scene = dynamic(() => import('@/components/canvas/Scene'), { ssr: false });

const Layout = ({ children }) => {
	const ref = useRef();
	const path = usePathname();

	if (path.includes('splat-viewer')) {
		return children;
	}

	return (
		<div
			ref={ref}
			className='relative flex h-full w-full touch-auto flex-col items-center justify-center overflow-auto bg-black text-white'
		>
			{children}
		</div>
	);
};

export { Layout };
