'use client';
import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
	ApolloNextAppProvider,
	NextSSRApolloClient,
	NextSSRInMemoryCache,
	SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { getServerSession } from 'next-auth';
import { getSession } from 'next-auth/react';
import { FunctionComponent, PropsWithChildren } from 'react';

const ApolloWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const authLink = setContext(() => {
		if (typeof window === 'undefined') {
			return getServerSession().then((session) => {
				if (session && 'authToken' in session) {
					return {
						headers: {
							authorization: `Bearer ${session.authToken}`,
						},
					};
				}
				return {
					headers: {
						'x-hasura-role': 'public',
					},
				};
			});
		}
		return getSession().then((session) => {
			if (session && 'authToken' in session) {
				return {
					headers: {
						authorization: `Bearer ${session.authToken}`,
					},
				};
			}
			return {
				headers: {
					'x-hasura-role': 'public',
				},
			};
		});
	});

	const makeClient = () => {
		const httpLink = new HttpLink({
			uri: process.env.NEXT_PUBLIC_HASURA_PROJECT_ENDPOINT!,
			headers: {
				'content-type': 'application/json',
			},
		});

		return new NextSSRApolloClient({
			cache: new NextSSRInMemoryCache(),
			connectToDevTools: true,
			link:
				typeof window === 'undefined'
					? ApolloLink.from([
							authLink,
							new SSRMultipartLink({
								stripDefer: true,
							}),
							httpLink,
					  ])
					: ApolloLink.from([authLink, httpLink]),
		});
	};

	return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
};

export { ApolloWrapper };
